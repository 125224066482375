/* mobile-notice.css */
.mobile-notice {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #f8d7da;
    color: #721c24;
    text-align: center;
    padding: 10px;
    font-size: 16px;
    z-index: 1000;
  }
  
  .main-content {
    display: block;
  }
  
  @media (max-width: 768px) {
    .mobile-notice {
      display: block;
    }
    .main-content {
      display: none;
    }
  }
  