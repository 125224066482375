body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Karla', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.my-header {
  @apply mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white;
}

.my-cards {
  @apply block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700;
}
.mymodal {
    @apply backdrop-blur p-6 w-1/2 bg-yellow-200 
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  border: none;
  background: transparent;
  font-size: 20px;
}

.modal-content {
  /* Add your modal content styles here */
}

.tutorial-tiles {
  display: flex;
  flex-wrap: wrap;
}

.tutorial-tile {
  width: 30%;
  margin: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  text-align: center;
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.tutorial-tile:hover {
  background-color: #f0f0f0;
}
